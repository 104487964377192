<template>
  <v-app-bar
    app
    fixed
    :color="color"
    elevate-on-scroll
    elevation="18"
    class="rs-main-nav-bar"
  >
    <v-container class="d-flex align-center">
      <v-spacer v-if="!navItems"></v-spacer>
      <v-toolbar-title>
        <router-link to="/">
          <v-img
            to="/"
            lazy-src="@/assets/risk-score-logo-full-blue.svg"
            :max-width="250"
            src="@/assets/risk-score-logo-full-blue.svg"
            class="rs-logo"
            contain
          ></v-img>
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <template v-if="navItems">
        <div v-for="c in navItems" :key="c">
          <NavItem :componentName="c" />
        </div>
      </template>
    </v-container>
  </v-app-bar>
</template>

<script>
import NavItem from "@/components/NavItem";

export default {
  props: {
    color: {
      type: String,
      default: "white",
    },
    navItems: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    NavItem,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@media print {
  .rs-main-nav-bar {
    display: none;
  }
}
</style>
