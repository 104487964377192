<template>
  <component :is="componentInstance" />
</template>

<script>
export default {
  props: ["componentName"],
  computed: {
    componentInstance() {
      return () => import(`@/components/${this.componentName}`);
    },
  },
};
</script>
